import React from "react"
//import Helmet from 'react-helmet'
import { navigate } from "gatsby"
import { isLoggedIn } from "../services/auth"
import Layout from "../components/layout"
import { useQueryParam, StringParam } from "use-query-params"
import { connect } from "react-redux"
import withLocation from "../components/withLocation"


let Dashboard =
  typeof window !== `undefined`
    ? require("../components/dashboard").default
    : null

// Maps the props that are going to be sended
// to the component connected with Redux
const mapStateToProps = ({
  payloads,
  projects,
  selectedPayload,
  selectedProject,
  sharedWithMe,
  currentView,
  currentModal,
  userData,
  selectedToken
}) => {
  return {
    payloads,
    projects,
    selectedPayload,
    selectedProject,
    sharedWithMe,
    currentView,
    currentModal,
    userData,
    selectedToken
  }
}

// Send each action of the reducer as props
// to the component connected with Redux
const mapDispatchToProps = dispatch => {
  return {
    setProjects: value => dispatch({ type: "SET_PROJECTS", value }),
    setPayloads: value => dispatch({ type: "SET_PAYLOADS", value }),
    setSelectedProject: value =>
      dispatch({ type: "SET_SELECTED_PROJECT", value }),
    setSelectedPayload: value =>
      dispatch({ type: "SET_SELECTED_PAYLOAD", value }),
    setSharedWithMe: value => dispatch({ type: "SET_SHARED_WITH_ME", value }),
    changeViewTo: value => dispatch({ type: "SET_CURRENT_VIEW", value }),
    showModal: value => dispatch({ type: "SET_CURRENT_MODAL", value }),
    hideCurrentModal: value => dispatch({ type: "HIDE_CURRENT_MODAL", value }),
    setDefaultState: value => dispatch({ type: "SET_DEFAULT_STATE", value }),
    setUserData: value => dispatch({ type: "SET_USER_DATA", value }),
    setSelectedToken: value => dispatch({ type: "SET_SELECTED_TOKEN", value }),

  }
}
// Component connected with redux
const ConnectedDashboard = Dashboard
  ? connect(mapStateToProps, mapDispatchToProps)(Dashboard)
  : null

const Index = (props) => {
  const [payloadId] = useQueryParam("shareId", StringParam)
  const render = isLoggedIn()
  // If user is already logged in, forward them to the private area.
  if (typeof window !== `undefined`) {
    if (!isLoggedIn()) {
      navigate(`/login`)
    }
  }
  return (
    <Layout hideFooter={true}>
      {render && ConnectedDashboard && (
        <ConnectedDashboard queryPayloadId={payloadId} location={props.location} navigate={props.navigate} />
      )}
    </Layout>
  )
}

export default withLocation(Index)
